// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled from '@emotion/styled'
import BigNumber from 'bignumber.js'
import { useField, type FieldProps } from 'informed'
import { type ChangeEvent, type FocusEvent } from 'react'
import MaskedInput from 'react-text-mask'

import { Box, Flex, Text, type FlexProps } from '@chakra-ui/react'
import {
  formatCurrency,
  formatCurrencyBTC,
  formatCurrencyUS,
  Currency,
} from '@strike-apps/shared/utils'

import { Warning } from '../../typography/Warning'
import { ConditionalWrapper } from '../../layout/ConditionalWrapper'
import createNumberMask from './createNumberMask'

const getFontSize = (amount: string, currency: Currency) => {
  const formatted =
    currency === 'BTC'
      ? formatCurrencyBTC(amount.replace(/,/, '')).displayAmount
      : formatCurrencyUS(amount.replace(/,/, '')).displayAmount
  const totalDigitString = formatted.replace(',', '').replace('.', '')
  const length = totalDigitString?.length

  if (length <= 3) return 80
  if (length <= 5) return 64
  if (length <= 7) return 48

  return 32
}

const SafeBigNumber = BigNumber.clone({
  EXPONENTIAL_AT: [-1e9, 1e9],
})

const Input = styled(MaskedInput)`
  background-color: transparent;
  font-size: ${(props: { fontSize: number }) => props.fontSize}px;
  line-height: inherit;
  border: none;
  text-align: center;
  outline: none;
  font-weight: 500;

  ::placeholder {
    opacity: 1;
    color: ${(props: { placeholderColor: number }) => props.placeholderColor};
  }
  ::-webkit-input-placeholder {
    line-height: normal !important;
  }
`

const InputSizer = styled.div`
  display: inline-grid;
  vertical-align: top;
  position: relative;
  &::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre-wrap;
  }
  input,
  &::after {
    font-size: ${(props: { fontSize: number }) => props.fontSize}px;
    line-height: inherit;
    width: auto;
    min-width: 20px;
    grid-area: 1 / 2;
    resize: none;
    background: none;
    appearance: none;
    border: none;
  }
`

const defaultMaskOptions = {
  allowLeadingZeroes: false,
  prefix: '',
  allowDecimal: true,
  allowNegative: false,
  integerLimit: 6,
  decimalSymbol: '.',
  decimalSymbolAlt: ',',
  includeThousandsSeparator: false,
}

function getNumberMask(decimalLimit: number) {
  return createNumberMask({
    ...defaultMaskOptions,
    decimalLimit,
  })
}

export type CurrencyInputType = 'simple' | 'card'

interface UserProps {
  clean?: (v: string) => string
  mask?: (v: string) => string
  parser?: (v: string) => string
}

export interface CurrencyInputProps extends Omit<FlexProps, 'onChange'> {
  fieldName: string
  description?: string
  currency?: Currency
  keepState?: boolean
  onBlur?: () => void
  onChange?: (e: ChangeEvent) => void
  language?: string
  hasDynamicFontSize?: boolean
  constantFontSize?: number
  isInvalid?: boolean
  invalidTextColor?: string
  align?: 'center' | 'left' | 'right'
  placeholderColor?: string
  type?: CurrencyInputType
  informed?: Omit<FieldProps<UserProps>, 'name'>
}

export const CurrencyInput = ({
  fieldName,
  keepState,
  onChange,
  currency = Currency.USD,
  language = 'en',
  hasDynamicFontSize = true,
  constantFontSize = 80,
  isInvalid = false,
  description,
  placeholderColor = '#05050552',
  invalidTextColor = 'faceNegative',
  align = 'center',
  type = 'simple',
  informed,
  ...rest
}: CurrencyInputProps) => {
  // Give the input a little additional width for padding
  const { fieldState, fieldApi } = useField({
    name: fieldName,
    keepState,
    ...informed,
  })

  const { setValue } = fieldApi
  const value = (fieldState.value as string) ?? ''
  const valueBig = SafeBigNumber(value)
  const placeholder = '0'

  // Checks whether specified value is set and is greater than zero.
  const hasValue = valueBig.gt(0)
  const { symbol } = formatCurrency({ amount: '0', currency }, language)
  const fontSize = hasDynamicFontSize ? getFontSize(value, currency) : constantFontSize

  const decimalLimit = currency === 'BTC' ? 8 : 2

  const onBlurHandler = () => {
    if (hasValue) {
      setValue(SafeBigNumber(value).toFixed(decimalLimit))
    }
    fieldApi.setTouched(true)
  }

  const symbolOffset = Math.round(fontSize / 16)

  const inputBody = (
    <Flex alignItems="center" justifyContent={align} {...rest}>
      <Text
        color={isInvalid ? invalidTextColor : 'facePrimary'}
        fontSize={fontSize}
        fontWeight="medium"
        mr={{
          base: 0,
          md: value.length > 1 ? `-${symbolOffset}px` : `-${3 * symbolOffset}px`,
        }}
      >
        {symbol}
      </Text>
      <Box color={isInvalid ? invalidTextColor : 'facePrimary'}>
        <InputSizer data-value={value} fontSize={fontSize}>
          <Input
            // NOTE: `key` is passed in here to force a re-render.
            // The re-render does not happen when decimalLimit changes because of a bug in the lib.
            // The lib detects if `mask` has changed by calling .toString() on a function.
            // https://github.com/text-mask/text-mask/blob/master/react/src/reactTextMask.js#L46
            // This lib is not maintained and we need to replace it.
            key={decimalLimit}
            mask={getNumberMask(decimalLimit)}
            autoComplete="off"
            autoFocus
            fontSize={fontSize}
            placeholderColor={placeholderColor}
            inputMode="decimal"
            size={1}
            name={fieldName}
            onBlur={onBlurHandler}
            onChange={(e: ChangeEvent) => {
              // @ts-expect-error: Property 'value'
              const { value: v } = e.target
              let newVal = v.replace(',', '.')

              // when user types in just '.' into the input field
              // due to the way react-text-mask works
              // the v value is set to '0._' ...
              //
              // https://github.com/text-mask/text-mask/issues/890
              //
              // ... this renders with the decimal half cut off
              // '0.' is another valid thing to enter - that is similar to what was typed -
              // that renders correctly
              if (newVal === '0._') {
                newVal = '0.'
              }
              setValue(newVal)

              if (onChange) {
                onChange(e)
              }
            }}
            // @ts-expect-error: Property 'select'
            onFocus={(e: FocusEvent) => e.target.select()}
            placeholder={placeholder}
            value={value}
          />
        </InputSizer>
      </Box>
    </Flex>
  )

  const errorBody = (
    <Warning pt={2} alignSelf="center" isInvalid>
      {fieldState.error as string}
    </Warning>
  )

  return (
    <ConditionalWrapper
      condition={type === 'card'}
      wrapper={(children) => (
        <Box>
          <Flex
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
            backgroundColor="layerPrimary"
            borderColor="borderAccent"
            borderWidth="1px"
            borderRadius={16}
            px={4}
            py={6}
          >
            {description && (
              <Text variant="body3" color="facePrimary">
                {description}
              </Text>
            )}

            <Box>{children}</Box>
          </Flex>
          {fieldState.showError && errorBody}
        </Box>
      )}
    >
      {inputBody}
      {type === 'simple' && fieldState.showError && errorBody}
    </ConditionalWrapper>
  )
}
