import { getBrowserLanguage, getCurrencyFormatter } from '@strike-apps/shared/utils'
import { useServerSide } from './useServerSide'

/**
 * Provides formatCurrency function that allows to translate number amount to currency format.
 * @param currency
 */
export const useCurrency = (currency = 'USD') => {
  const { isBrowser } = useServerSide()

  const formatter = getCurrencyFormatter(currency, isBrowser ? getBrowserLanguage() : 'en')
  const currencySymbol = formatter.formatToParts(0).find((item) => item.type === 'currency')?.value

  return {
    currencySymbol,
    formatCurrency: (amount: number): string | undefined => formatter.format(amount),
  }
}
