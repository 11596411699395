import { Flex, type FlexProps } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { Text } from '../../typography/Text'

export interface FooterProps extends FlexProps {
  logo?: ReactNode
  copyrightNotice?: ReactNode
}

export const Footer = ({ logo, copyrightNotice = '© 2024 Strike', ...rest }: FooterProps) => {
  const includeLogo = Boolean(logo)

  return (
    <Flex
      textAlign={['left', 'center']}
      p={['24px', '40px']}
      color="faceSecondary"
      justifyContent={includeLogo ? 'space-between' : 'center'}
      alignItems="baseline"
      {...rest}
    >
      {includeLogo && logo}
      <Text variant="subheadline2" color="faceSecondary">
        {copyrightNotice}
      </Text>
    </Flex>
  )
}
