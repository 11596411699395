export { ReactComponent as UserIcon } from './user.svg'
export { ReactComponent as HomeIcon } from './home.svg'
export { ReactComponent as BankIcon } from './bank.svg'
export { ReactComponent as SendIcon } from './send.svg'
export { ReactComponent as RewardsIcon } from './rewards.svg'
export { ReactComponent as SettingsIcon } from './settings.svg'
export { ReactComponent as MailIcon } from './mail.svg'
export { ReactComponent as WalletIcon } from './wallet.svg'
export { ReactComponent as CardIcon } from './card.svg'
export { ReactComponent as CareersIcon } from './careers.svg'
export { ReactComponent as PersonalInformationIcon } from './personal-information.svg'

export { ReactComponent as ChatIcon } from './chat.svg'
export { ReactComponent as ChatNotificationIcon } from './chat-notification.svg'
export { ReactComponent as SearchIcon } from './search.svg'
export { ReactComponent as CheckIcon } from './check.svg'
export { ReactComponent as ExtraIcon } from './extra.svg'
export { ReactComponent as PhoneIcon } from './phone.svg'
export { ReactComponent as GiftIcon } from './gift.svg'
export { ReactComponent as LimitsIcon } from './limits.svg'
export { ReactComponent as BitcoinSignIcon } from './bitcoin-sign.svg'
export { ReactComponent as FilterIcon } from './filter.svg'
export { ReactComponent as DeleteIcon } from './delete.svg'

export { ReactComponent as CopyIcon } from './copy.svg'
export { ReactComponent as LinkIcon } from './link.svg'
export { ReactComponent as BrokenLinkIcon } from './broken-link.svg'
export { ReactComponent as TransferIcon } from './transfer.svg'
export { ReactComponent as SortIcon } from './sort.svg'
export { ReactComponent as WithdrawIcon } from './withdraw.svg'
export { ReactComponent as DepositIcon } from './deposit.svg'
export { ReactComponent as UploadIcon } from './upload.svg'
export { ReactComponent as DownloadIcon } from './download.svg'
export { ReactComponent as ExpandIcon } from './expand.svg'
export { ReactComponent as CollapseIcon } from './collapse.svg'

export { ReactComponent as LockIcon } from './lock.svg'
export { ReactComponent as UnlockedIcon } from './unlocked.svg'
export { ReactComponent as ShowIcon } from './show.svg'
export { ReactComponent as HideIcon } from './hide.svg'
export { ReactComponent as CalendarIcon } from './calendar.svg'
export { ReactComponent as CalendarBookingIcon } from './calendar-booking.svg'
export { ReactComponent as PinIcon } from './pin.svg'
export { ReactComponent as PinBlockedIcon } from './pin-blocked.svg'
export { ReactComponent as AtIcon } from './at.svg'
export { ReactComponent as NullIcon } from './null.svg'
export { ReactComponent as GlobalPaymentIcon } from './global-payment.svg'

export { ReactComponent as PieChartIcon } from './pie-chart.svg'
export { ReactComponent as ClockIcon } from './clock.svg'
export { ReactComponent as ClockNotificationIcon } from './clock-notification.svg'
export { ReactComponent as InfoIcon } from './info.svg'
export { ReactComponent as AlertIcon } from './alert.svg'
export { ReactComponent as QuestionIcon } from './question.svg'
export { ReactComponent as CirclePlusIcon } from './circle-plus.svg'
export { ReactComponent as CircleMinusIcon } from './circle-minus.svg'
export { ReactComponent as CircleCleanIcon } from './circle-clean.svg'
export { ReactComponent as CircleCheckIcon } from './circle-check.svg'
export { ReactComponent as CircleUserIcon } from './circle-user.svg'

export { ReactComponent as CircleUserBlockedIcon } from './circle-user-blocked.svg'
export { ReactComponent as NumberUpIcon } from './number-up.svg'
export { ReactComponent as NumberDownIcon } from './number-down.svg'
export { ReactComponent as TrashIcon } from './trash.svg'
export { ReactComponent as KeyIcon } from './key.svg'
export { ReactComponent as CleanIcon } from './clean.svg'
export { ReactComponent as ShoppingBagIcon } from './shopping-bag.svg'
export { ReactComponent as DocumentIcon } from './document.svg'
export { ReactComponent as WrenchIcon } from './wrench.svg'
export { ReactComponent as GridIcon } from './grid.svg'
export { ReactComponent as ListIcon } from './list.svg'
export { ReactComponent as EditIcon } from './edit.svg'

export { ReactComponent as LogoutIcon } from './logout.svg'

export { ReactComponent as RefreshIcon } from './refresh.svg'
