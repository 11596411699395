import { Box, type BoxProps } from '../../layout/Box'

const styles = {
  '*': {
    color: 'faceTertiary',
  },
  'h1, h2, h3, h4, h5, h6': {
    fontWeight: 'bold',
    color: 'facePrimary',
  },
  h1: {
    fontSize: {
      base: '48px',
      lg: '72px',
      xl: '96px',
    },
    lineHeight: {
      base: '48px',
      lg: '64px',
      xl: '88px',
    },
    marginBottom: 6,
  },
  'h1 ~ h4': {
    fontSize: '13px',
    lineHeight: 4,
    fontWeight: 'normal',
    color: 'faceTertiary',
    marginBottom: 6,
  },
  h2: {
    fontSize: '24px',
    lineHeight: '28px',
    marginTop: 12,
    marginBottom: 6,
  },
  h3: {
    fontSize: '24px',
    lineHeight: '28px',
    marginTop: 12,
    marginBottom: 4,
  },
  a: {
    color: 'facePrimary',
    fontWeight: 'bold',
    wordBreak: 'break-word',
  },
  p: {
    fontWeight: 'normal',
    '&:not(:last-child)': {
      marginBottom: 5,
    },
  },
  'ul li': {
    listStyleType: 'disc',
  },
  'ol li': {
    listStyleType: 'decimal',
  },
  'ol li, ul li': {
    fontSize: '17px',
    lineHeight: 6,
    fontWeight: 'medium',
    color: 'faceTertiary',
    marginLeft: '36px',
    marginBottom: '16px',
    '&:last-of-type': {
      marginBottom: 0,
    },
    strong: {
      color: 'facePrimary',
    },
    ul: {
      marginTop: 6,
      li: {
        listStyleType: 'circle',
        marginLeft: '36px',
      },
    },
  },
  table: {
    fontSize: '17px',
    lineHeight: 6,
    borderColor: 'borderSecondary!',
    border: '1px solid',
    borderCollapse: 'collapse',
    padding: '5px',
    marginBottom: 6,
    '&:not(:last-child)': {
      marginBottom: 5,
    },
    th: {
      textAlign: 'left',
      fontWeight: 'bold',
      marginBottom: 5,
      color: 'white',
    },
    'th, td': {
      width: '50%',
      borderColor: 'borderSecondary!',
      border: '1px solid',
      borderCollapse: 'collapse',
      padding: '10px',
    },
    tbody: {
      width: '50%',
    },
  },
}

export function LegalMdxWrapper(props: BoxProps) {
  return <Box __css={styles} {...props} />
}
