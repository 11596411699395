export enum Currency {
  BTC = 'BTC',
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  USDT = 'USDT',
} /** Represents an amount denominated in fiat or crypto Currency. */

export interface CurrencyAmount {
  /**
   * Currency code
   * @example "USD"
   */
  currency: Currency
  /**
   * Currency amount in decimal format
   * @example "10.99"
   */
  amount: string
}

export const getCurrencyFormatter = (currency: string, locale: string) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })
}

export const convertBtcToSatoshi = (amount: string) => {
  const parsedAmount = parseFloat(amount)
  return (parsedAmount / 0.00000001).toString()
}

// Aux currency code to provide USD + en_US currency formatting

const createBtcFormatter = (locale: string): Intl.NumberFormat => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: Currency.BTC,
    minimumFractionDigits: 2,
    maximumFractionDigits: 8,
  })

  return {
    ...formatter,
    format: (value: number): string => {
      // Replace non-breaking space before the symbol
      const formattedValue = formatter.format(value).replace('\u00A0', '')
      return formattedValue.replace(/BTC/g, '₿')
    },
  }
}

const createCurrencyFormatter = (currency: Currency, locale: string): Intl.NumberFormat => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })
}

export const getFormatter = (currency: Currency, locale: string) => {
  const formatters = {
    [Currency.BTC]: createBtcFormatter(locale),
    'US-USD': createCurrencyFormatter(Currency.USD, 'en-US'),
    [Currency.USD]: createCurrencyFormatter(Currency.USD, locale),
    [Currency.USDT]: createCurrencyFormatter(Currency.USD, locale),
    [Currency.EUR]: createCurrencyFormatter(Currency.EUR, locale),
    [Currency.GBP]: createCurrencyFormatter(Currency.GBP, locale),
  }

  const formatter = formatters[currency]

  if (!formatter) {
    throw new Error(`No formatter found for currency ${currency}`)
  }

  return formatter
}

const getCurrencySymbol = (currency: string, locale: string) => {
  if (currency === 'BTC') return '₿'

  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim()
}

export const formatCurrency = (value: CurrencyAmount, locale: string, includeSymbol = true) => {
  const { currency = Currency.USD, amount } = value
  const formatter = getFormatter(currency, locale)
  const displayAmount = formatter.format(Number(amount))

  return {
    displayAmount: includeSymbol ? displayAmount : displayAmount.replace(/[^0-9.,-]+/g, ''),
    symbol: getCurrencySymbol(currency === Currency.USDT ? 'USD' : currency, locale),
  }
}

export const isValidBtcAmount = (amount: string | number) => {
  const amt = typeof amount === 'string' ? parseFloat(amount) : amount

  if (!amount) {
    return false
  }

  const result = amt >= 0.00000001 && amt <= 21000000

  if (result) {
    const decimalPlaces = amount.toString().split('.')[1]?.length ?? 0
    return decimalPlaces <= 8
  }

  return result
}

export const formatCurrencyUS = (amount: string, includeSymbol = false) =>
  formatCurrency({ currency: Currency.USD, amount }, 'en', includeSymbol)

export const formatCurrencyBTC = (amount: string, includeSymbol = false) =>
  formatCurrency({ currency: Currency.BTC, amount }, 'en', includeSymbol)
