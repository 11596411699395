import {
  MenuButton as BaseMenuButton,
  Box,
  Icon,
  Menu,
  MenuItem,
  MenuList,
  useMultiStyleConfig,
  type ButtonProps,
  type MenuItemProps,
  type MenuListProps,
  type MenuProps,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@strike-apps/shared/icons'
import { createContext, useContext, type FC, type ReactNode } from 'react'
import type { SelectSize, SelectVariant } from '../../../theme/components/select'
import { MenuButton } from '../../overlay/Menu'
import type { ButtonColorScheme } from '../Button'

interface SelectContextData {
  size: string
  variant: string
}
const SelectContext = createContext<SelectContextData>({} as SelectContextData)

export interface SelectProps extends MenuProps {
  children: ReactNode
  variant?: SelectVariant
  size?: SelectSize
  colorScheme?: ButtonColorScheme
}

type SelectComponent = FC<SelectProps> & {
  Button: FC<SelectButtonProps>
  List: FC<MenuListProps>
  Option: FC<MenuItemProps>
}

/**
 * We needed to create our own <Select> component using <Menu> instead
 * of the <Select> from Chakra UI because we need more control over
 * option and icon styles. Context: https://github.com/LN-Zap/strike-apps/pull/615
 */
const Select: SelectComponent = ({ children, variant = 'outline', size = 'md', ...rest }) => {
  return (
    <SelectContext.Provider value={{ size, variant }}>
      <Menu matchWidth {...rest} isLazy id="menu">
        {children}
      </Menu>
    </SelectContext.Provider>
  )
}

interface SelectButtonProps extends Omit<ButtonProps, 'size' | 'variant' | 'rightIcon'> {
  rightIcon?: ReactNode
}

const SelectButton: FC<SelectButtonProps> = ({ rightIcon, children, ...rest }) => {
  const parentProps = useContext(SelectContext)
  const styles = useMultiStyleConfig('Select', parentProps)

  return (
    <BaseMenuButton
      as={MenuButton}
      size="hug"
      rightIcon={
        rightIcon ? (
          <Box __css={styles['icon']}>{rightIcon}</Box>
        ) : (
          <Icon as={ChevronDownIcon} __css={styles['icon']} />
        )
      }
      sx={styles['button']}
      {...rest}
    >
      {children}
    </BaseMenuButton>
  )
}

const SelectList: FC<MenuListProps> = ({ children, ...rest }) => {
  return (
    <MenuList maxH="300px" overflowY="auto" {...rest}>
      {children}
    </MenuList>
  )
}

const SelectOption: FC<MenuItemProps> = ({ children, ...rest }) => {
  const parentProps = useContext(SelectContext)
  const styles = useMultiStyleConfig('Select', parentProps)

  return (
    <MenuItem {...rest} sx={styles['option']}>
      {children}
    </MenuItem>
  )
}

Select.Button = SelectButton
Select.Option = SelectOption
Select.List = SelectList

export { Select }
