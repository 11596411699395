import { useColorMode } from '@chakra-ui/system'
import { useEnvSettings } from '@strike-apps/commerce-dashboard/env-settings'
import { Alert, Page, UkBanner } from '@strike-apps/commerce-dashboard/ui'
import { getIdpLogoutUrl } from '@strike-apps/commerce-dashboard/util-auth/logout-url'
import { arePermissionsSufficient } from '@strike-apps/commerce-dashboard/util-auth/session-permissions'
import { Feature, useFeatureFlag } from '@strike-apps/shared/feature-flags/react'

import { useIsUK, useIsUS } from '@strike-apps/commerce-dashboard/hooks'
import { useSupportLink } from '@strike-apps/commerce-dashboard/util-support'
import {
  ChevronRightIcon,
  CopyIcon,
  InfoIcon,
  ListIcon,
  LogoutIcon,
  MailIcon,
  QuestionIcon,
  TransferIcon,
} from '@strike-apps/shared/icons'
import {
  Avatar,
  BaseMenuButton,
  MenuDivider as BaseMenuDivider,
  Box,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuHeader,
  MenuItem,
  MenuList,
  Show,
  Switch,
  Text,
  useCopy,
  type MenuItemProps,
} from '@strike-apps/shared/ui'
import { REFRESH_ACCESS_TOKEN_ERROR } from '@strike-apps/strike/oauth'
import { signIn, useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useEffect, type FC, type ReactElement, type ReactNode } from 'react'
import { useUserSettings } from '../context/UserSettingsContext'
import { DropdownMenu } from './DropdownMenu'
import { SidebarMenu } from './SidebarMenu'

interface LinkMenuItemProps extends MenuItemProps {
  href: string
  rightIcon?: React.ElementType
}

const LinkMenuItem = ({ rightIcon = ChevronRightIcon, ...rest }: LinkMenuItemProps) => {
  return (
    <MenuItem
      as={Link}
      textDecoration="none"
      _hover={{ textDecoration: 'none' }}
      rightContent={<Icon as={rightIcon} color="faceSecondary" boxSize="20px" />}
      {...rest}
    />
  )
}

interface CopyMenuItemProps {
  heading: string
  description: string
  copyText: string
  onCopyMessage: string
}

const CopyMenuItem = ({ heading, copyText, onCopyMessage, description }: CopyMenuItemProps) => {
  const handleCopy = useCopy(copyText, onCopyMessage)

  return (
    <MenuItem
      rightContent={<Icon as={CopyIcon} color="faceSecondary" boxSize="20px" />}
      onClick={handleCopy}
      height="auto"
    >
      <Box>
        <Flex alignItems="center" marginBottom={'4px'}>
          <Text variant={'subheadline2'} color={'facePrimary'}>
            {heading}
          </Text>
        </Flex>
        <Text color={'faceTertiary'} variant={'mono2'} fontFamily="mono">
          {description}
        </Text>
      </Box>
    </MenuItem>
  )
}

function MenuDivider() {
  return <BaseMenuDivider mx={3} my={0} />
}

export interface ProtectedLayoutProps {
  children: ReactNode
  title: string
}

function HelpMenu() {
  const { emailHref } = useSupportLink()
  return (
    <Menu computePositionOnMount>
      <BaseMenuButton
        as={IconButton}
        icon={<Icon as={QuestionIcon} boxSize={5} />}
        variant="ghost"
        size="cd"
        role="menu"
        aria-label="Help menu"
      />
      <MenuList minW="320px">
        <LinkMenuItem href="/faq">FAQ</LinkMenuItem>
        <MenuDivider />
        <LinkMenuItem href="https://docs.strike.me">API docs</LinkMenuItem>
        <MenuDivider />
        <LinkMenuItem rightIcon={MailIcon} href={emailHref}>
          Contact support
        </LinkMenuItem>
      </MenuList>
    </Menu>
  )
}

export const ProtectedLayout: FC<ProtectedLayoutProps> = ({ title, children }) => {
  const { data: session } = useSession()
  const envSettings = useEnvSettings()
  const router = useRouter()
  const { userMode } = useUserSettings()
  const { colorMode, toggleColorMode } = useColorMode()
  const isLightModeAvailable = useFeatureFlag('feat-light-mode', false)
  const userName = session?.user?.name || session?.user?.username
  const handle = session?.user?.username || ''
  const preMainContent = usePreMainContent()

  const handleSignOut = useCallback(() => {
    if (session) {
      const signOutUrl = getIdpLogoutUrl(session, envSettings)
      window.location.href = signOutUrl
    }
  }, [envSettings, session])

  useEffect(() => {
    if (
      session?.error === REFRESH_ACCESS_TOKEN_ERROR ||
      session?.user.secondFactorStatus === undefined
    ) {
      handleSignOut() // Force sign out to hopefully resolve error upon next login
    }
  }, [session, handleSignOut])

  const userMenu = (
    <Menu computePositionOnMount>
      <BaseMenuButton
        as={IconButton}
        icon={<Icon as={ListIcon} boxSize={5} />}
        variant="ghost"
        size="cd"
        role="menu"
        aria-label="User menu"
      />
      <MenuList minW="320px">
        <MenuHeader
          label={userName as string}
          leftContent={
            <Avatar
              name={userName}
              initialOnly
              size="2xs"
              rounded="full"
              bg="objectAccent"
              color="facePrimaryAlt"
            />
          }
        />
        <CopyMenuItem
          heading="Username"
          copyText={handle}
          description={handle}
          onCopyMessage="Username copied"
        />
        <MenuDivider />
        <Feature featureKey="feature-enable-2fa">
          <LinkMenuItem href="/profile">Profile</LinkMenuItem>
        </Feature>
        <LinkMenuItem href="/api-keys">API keys</LinkMenuItem>
        <MenuDivider />
        <MenuItem
          closeOnSelect={false}
          isDisabled={!isLightModeAvailable}
          rightContent={
            <Switch
              size="md"
              isChecked={colorMode === 'dark'}
              disabled={!isLightModeAvailable}
              onChange={isLightModeAvailable ? toggleColorMode : undefined}
            />
          }
        >
          Dark mode
        </MenuItem>
        <MenuDivider />
        <MenuItem
          color="faceNegative"
          rightContent={<Icon as={LogoutIcon} boxSize="20px" />}
          onClick={handleSignOut}
        >
          Log out
        </MenuItem>
      </MenuList>
    </Menu>
  )

  const headerContent = (
    <Flex alignItems="center" gap={2}>
      <HelpMenu />
      {userMenu}
    </Flex>
  )

  const renderNextLink = (children: ReactElement, targetRoute: string) => {
    return <Link href={targetRoute}>{children}</Link>
  }

  const sidebarContent = (
    <SidebarMenu mode={userMode} activeRoute={router.pathname} renderLink={renderNextLink} />
  )

  const isUK = useIsUK()

  return (
    <Page
      title={
        <>
          <Show below="sm">
            <DropdownMenu mode={userMode} activeRoute={title} />
          </Show>
          <Show above="sm">{title}</Show>
        </>
      }
      headerContent={headerContent}
      headerStickyContent={isUK && <UkBanner />}
      sidebarContent={sidebarContent}
      preMainContent={preMainContent && <PreMainContent type={preMainContent} />}
    >
      {children}
    </Page>
  )
}

type PreMainContentType = 'permissions-alert' | 'sandbox' | 'ach-promo' | null

function usePreMainContent(): PreMainContentType {
  const { data: session } = useSession()
  const isUS = useIsUS()
  const { strikeApiScope, isSandboxEnvironment } = useEnvSettings()
  const isACHEnabled = useFeatureFlag('feat-enable-ach-payment-methods', false)

  if (!arePermissionsSufficient(session, strikeApiScope)) {
    return 'permissions-alert'
  }

  if (isACHEnabled && isUS) {
    return 'ach-promo'
  }

  if (isSandboxEnvironment) {
    return 'sandbox'
  }

  return null
}

const PreMainContent = ({ type }: { type: PreMainContentType }) => {
  const router = useRouter()
  switch (type) {
    case 'permissions-alert':
      return (
        <Alert
          title="Required permissions have changed"
          description="Please re-authorize your application to continue using the dashboard. You will be directed to the authorization page."
          actionText="Allow new permissions"
          icon={InfoIcon}
          onAction={() => signIn('strike')}
        />
      )

    case 'ach-promo':
      return router.pathname !== '/accounts' ? (
        <Alert
          title="ACH bank deposits are here"
          description="Link a bank account within your Strike Dashboard and buy bitcoin instantly."
          actionText="Link a bank account"
          icon={TransferIcon}
          onAction={() => router.push('/accounts')}
        />
      ) : null

    case 'sandbox':
      return <Alert title="Sandbox Environment" />
    default:
      return null
  }
}
