import { type BoxProps, Flex } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/react'
export { Icon, type IconProps } from '@chakra-ui/react'

type IconWithBackgroundProps = BoxProps & {
  icon: React.FC
}

export function IconWithBackground({ icon, ...rest }: IconWithBackgroundProps) {
  return (
    <Flex
      boxSize={9}
      borderRadius="12px"
      background="layerSecondary"
      justify="center"
      align="center"
      className="icon"
      {...rest}
    >
      <Icon as={icon} boxSize={5} />
    </Flex>
  )
}
