import { CheckIcon, CloseIcon, InfoIcon } from '@strike-apps/shared/icons'
import type { FC } from 'react'
import { InfoCard } from '../../data-display/Card'
import { type FlexProps } from '../../layout/Flex'

export interface ToastProps extends FlexProps {
  onClose: () => void
  toastType?: 'warning' | 'error' | 'success' | 'accent'
  icon?: FC
  title?: string
  message: string
  linkLabel?: string
  linkAction?: () => void
}

export const Toast = ({
  onClose,
  toastType = 'warning',
  icon,
  title = '',
  message,
}: ToastProps) => {
  return (
    <InfoCard
      w={['100%', '340px']}
      p={4}
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.16), 0px 2px 8px rgba(0, 0, 0, 0.4)"
      title={title}
      description={message}
      mainColor={getMainColor(toastType)}
      iconColor={getIconColor(toastType)}
      icon={getIcon(toastType, icon)}
      onClose={onClose}
    />
  )
}

const getMainColor = (toastType: ToastProps['toastType']) => {
  if (toastType === 'error') {
    return 'faceNegative'
  }

  if (toastType === 'warning') {
    return 'faceWarning'
  }

  return 'facePrimary'
}
const getIconColor = (toastType: ToastProps['toastType']) => {
  if (toastType === 'error') {
    return 'faceNegative'
  }

  if (toastType === 'warning') {
    return 'faceWarning'
  }

  if (toastType === 'success') {
    return 'facePositive'
  }

  return 'facePrimary'
}

const getIcon = (toastType: ToastProps['toastType'], icon: ToastProps['icon']) => {
  if (toastType === 'error' && !icon) {
    return CloseIcon
  }

  if (toastType === 'warning' && !icon) {
    return InfoIcon
  }

  return icon ?? CheckIcon
}
