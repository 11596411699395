import { isGDPRCountry } from './gdpr'

/**
 * getRegionFromCountryCode  get region from country code
 * @param countryCode country code
 * @returns `europe` for GDPR EU countries, `uk`, `us`, or the country code
 */
export const getRegionFromCountryCode = (countryCode: string): 'europe' | 'uk' | 'us' | string => {
  if (!countryCode) {
    return ''
  }

  return isGDPRCountry(countryCode.toLowerCase()) ? 'europe' : countryCode
}
