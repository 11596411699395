import axios from 'axios'

export type FetchParams = {
  query: string
  variables?: Record<string, string | number | string[] | number[]>
  apiUrl?: string
  apiToken?: string
  includeDrafts?: boolean
}

export async function fetchAPI({
  query,
  variables,
  includeDrafts,
  apiUrl = 'https://graphql.datocms.com',
  apiToken,
}: FetchParams) {
  const { data } = await axios.post(
    apiUrl,
    JSON.stringify({
      query,
      variables,
    }),
    {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        ...(includeDrafts ? { 'X-Include-Drafts': 'true' } : {}),
      },
    },
  )

  if (data.errors) {
    console.error(data.errors)
    throw new Error('Failed to fetch API')
  }
  return data.data
}
