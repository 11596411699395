import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'

export const radioStyles: ComponentMultiStyleConfig = {
  parts: ['label'],
  baseStyle: {
    label: {
      textStyle: 'body3',
      fontWeight: 700,
    },
  },
}
