import type { ComponentSingleStyleConfig } from '@chakra-ui/theme'

export const linkStyles: ComponentSingleStyleConfig = {
  baseStyle: {
    textStyle: 'subline',
    color: 'white',
    fontWeight: 'bold',
    textDecoration: 'underline',

    _hover: {
      color: 'faceSecondary',
    },
  },
}
