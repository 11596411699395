import { LDProvider } from 'launchdarkly-react-client-sdk'
import { type LDOptions, initialize } from 'launchdarkly-js-client-sdk'
import type { Session } from 'next-auth'
import { parseUserContext } from '../../utils/parse'
import { useServerSide } from '@strike-apps/shared/hooks'
import { FeatureFlagsContextProvider, useFeatureFlagsContext } from './FeatureFlagsContext'
import type { ReactNode } from 'react'

export interface FeatureFlagsProviderProps {
  clientSideId: string
  options?: LDOptions
  session?: Session
  children: React.ReactNode
}

export const FeatureFlagsProvider = ({ children, ...rest }: FeatureFlagsProviderProps) => {
  const { isServer } = useServerSide()

  return (
    <FeatureFlagsContextProvider value={{ ...rest }}>
      {isServer ? children : <LaunchDarklyProvider>{children}</LaunchDarklyProvider>}
    </FeatureFlagsContextProvider>
  )
}

const LaunchDarklyProvider = ({ children }: { children: ReactNode }) => {
  const { clientSideId, options, session } = useFeatureFlagsContext()
  const ldClient = initialize(clientSideId, parseUserContext(session), options)

  return (
    <LDProvider clientSideID={clientSideId} options={options} ldClient={ldClient}>
      {children}
    </LDProvider>
  )
}
