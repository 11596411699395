import { dashboardTheme } from '@strike-apps/commerce-dashboard/ui'
import { StrikeUiProvider } from '@strike-apps/shared/ui'
import type { ReactNode } from 'react'

export interface StrikeDashboardUiProviderProps {
  children: ReactNode
}
export const StrikeDashboardUiProvider = ({ children }: StrikeDashboardUiProviderProps) => {
  return (
    <StrikeUiProvider
      features={{
        sideBarFullWidthBreakpoint: 'xl',
      }}
      theme={dashboardTheme}
    >
      {children}
    </StrikeUiProvider>
  )
}
