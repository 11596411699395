import { CSSReset, ChakraProvider, createCookieStorageManager } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import {
  StrikeUiContextProvider,
  type StrikeUiFeatures,
  type StrikeUiOptions,
} from '../context/StrikeUiContext'
import { colorModeCookieKeyName, defaultTheme } from '../theme'

export interface StrikeUiProviderProps {
  children: ReactNode
  theme?: typeof defaultTheme
  options?: StrikeUiOptions
  features?: StrikeUiFeatures
  cookies?: string
}

const manager = createCookieStorageManager(colorModeCookieKeyName)
export const StrikeUiProvider = (props: StrikeUiProviderProps) => {
  return (
    <ChakraProvider theme={props.theme ?? defaultTheme} colorModeManager={manager}>
      <StrikeUiContextProvider
        value={{
          options: props.options,
          features: props.features,
        }}
      >
        <CSSReset />
        {props.children}
      </StrikeUiContextProvider>
    </ChakraProvider>
  )
}
