export { default as UserIcon } from './user.svg'
export { default as HomeIcon } from './home.svg'
export { default as BankIcon } from './bank.svg'
export { default as SendIcon } from './send.svg'
export { default as RewardsIcon } from './rewards.svg'
export { default as SettingsIcon } from './settings.svg'
export { default as MailIcon } from './mail.svg'
export { default as WalletIcon } from './wallet.svg'
export { default as CardIcon } from './card.svg'
export { default as CareersIcon } from './careers.svg'
export { default as PersonalInformationIcon } from './personal-information.svg'

export { default as ChatIcon } from './chat.svg'
export { default as ChatNotificationIcon } from './chat-notification.svg'
export { default as SearchIcon } from './search.svg'
export { default as CheckIcon } from './check.svg'
export { default as ExtraIcon } from './extra.svg'
export { default as PhoneIcon } from './phone.svg'
export { default as GiftIcon } from './gift.svg'
export { default as LimitsIcon } from './limits.svg'
export { default as BitcoinSignIcon } from './bitcoin-sign.svg'
export { default as FilterIcon } from './filter.svg'
export { default as DeleteIcon } from './delete.svg'

export { default as CopyIcon } from './copy.svg'
export { default as LinkIcon } from './link.svg'
export { default as BrokenLinkIcon } from './broken-link.svg'
export { default as TransferIcon } from './transfer.svg'
export { default as SortIcon } from './sort.svg'
export { default as WithdrawIcon } from './withdraw.svg'
export { default as DepositIcon } from './deposit.svg'
export { default as UploadIcon } from './upload.svg'
export { default as DownloadIcon } from './download.svg'
export { default as ExpandIcon } from './expand.svg'
export { default as CollapseIcon } from './collapse.svg'

export { default as LockIcon } from './lock.svg'
export { default as UnlockedIcon } from './unlocked.svg'
export { default as ShowIcon } from './show.svg'
export { default as HideIcon } from './hide.svg'
export { default as CalendarIcon } from './calendar.svg'
export { default as CalendarBookingIcon } from './calendar-booking.svg'
export { default as PinIcon } from './pin.svg'
export { default as PinBlockedIcon } from './pin-blocked.svg'
export { default as AtIcon } from './at.svg'
export { default as NullIcon } from './null.svg'
export { default as GlobalPaymentIcon } from './global-payment.svg'

export { default as PieChartIcon } from './pie-chart.svg'
export { default as ClockIcon } from './clock.svg'
export { default as ClockNotificationIcon } from './clock-notification.svg'
export { default as InfoIcon } from './info.svg'
export { default as AlertIcon } from './alert.svg'
export { default as QuestionIcon } from './question.svg'
export { default as CirclePlusIcon } from './circle-plus.svg'
export { default as CircleMinusIcon } from './circle-minus.svg'
export { default as CircleCleanIcon } from './circle-clean.svg'
export { default as CircleCheckIcon } from './circle-check.svg'
export { default as CircleUserIcon } from './circle-user.svg'

export { default as CircleUserBlockedIcon } from './circle-user-blocked.svg'
export { default as NumberUpIcon } from './number-up.svg'
export { default as NumberDownIcon } from './number-down.svg'
export { default as TrashIcon } from './trash.svg'
export { default as KeyIcon } from './key.svg'
export { default as CleanIcon } from './clean.svg'
export { default as ShoppingBagIcon } from './shopping-bag.svg'
export { default as DocumentIcon } from './document.svg'
export { default as WrenchIcon } from './wrench.svg'
export { default as GridIcon } from './grid.svg'
export { default as ListIcon } from './list.svg'
export { default as EditIcon } from './edit.svg'

export { default as RefreshIcon } from './refresh.svg'
